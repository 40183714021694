import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

const NavigationMenu = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Root>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    ref={ref}
    className={twMerge(
      'fixed top-0 w-full z-50 transition duration-300',
      className,
    )}
    delayDuration={50}
    {...props}
  >
    {children}
    <NavigationMenuViewport className={className} />
  </NavigationMenuPrimitive.Root>
))
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName

const NavigationMenuList = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.List>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    ref={ref}
    className={twMerge(
      'flex flex-1 list-none items-center justify-center',
      className,
    )}
    {...props}
  />
))
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName

const NavigationMenuItem = NavigationMenuPrimitive.Item

const NavigationMenuTrigger = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={twMerge(
      'focus:outline-none disabled:pointer-events-none disabled:opacity-50',
      'flex items-center justify-self-center gap-2 group h-full',
      className,
    )}
    {...props}
  >
    {children}
  </NavigationMenuPrimitive.Trigger>
))
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName

const NavigationMenuContent = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    ref={ref}
    className={twMerge(
      'w-full lg:absolute',
      'data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out',
      'lg:group-data-[state=open]/viewport:duration-1000',
      'lg:group-data-[state=open]/viewport:animate-in lg:group-data-[state=open]/viewport:fade-in',
      'lg:group-data-[state=closed]/viewport:transition-opacity lg:group-data-[state=closed]/viewport:opacity-0',
      'data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out',
      'data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52',
      'data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52',
      className,
    )}
    {...props}
  />
))
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName

const NavigationMenuLink = NavigationMenuPrimitive.Link

const NavigationMenuViewport = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Viewport>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Viewport
    className={twMerge(
      'inset-x-0 w-full origin-bottom overflow-hidden overflow-y-auto group/viewport',
      'z-10 fixed top-24 bottom-0', // mobile (top matches the height of the HeaderBar)
      'lg:-z-10 lg:absolute lg:top-full lg:shadow-md lg:black:shadow-gray-800', //desktop
      'lg:h-[var(--radix-navigation-menu-viewport-height)]',
      'data-[state=open]:animate-in data-[state=closed]:animate-out',
      'data-[state=open]:duration-500 data-[state=closed]:duration-1000',
      'lg:data-[state=open]:slide-in-from-top lg:data-[state=closed]:slide-out-to-top',
      'lg:data-[state=open]:slide-in-from-right-0 lg:data-[state=closed]:slide-out-to-right-0',
      'data-[state=open]:slide-in-from-right data-[state=closed]:slide-out-to-right',
      'data-[state=open]:slide-in-from-top-0 data-[state=closed]:slide-out-to-top-0',
      className,
    )}
    ref={ref}
    {...props}
  />
))
NavigationMenuViewport.displayName =
  NavigationMenuPrimitive.Viewport.displayName

const NavigationMenuIndicator = forwardRef<
  ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    ref={ref}
    className={twMerge(
      'top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in',
      className,
    )}
    {...props}
  >
    <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-border shadow-md" />
  </NavigationMenuPrimitive.Indicator>
))
NavigationMenuIndicator.displayName =
  NavigationMenuPrimitive.Indicator.displayName

export {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
}
