import { useState, useEffect } from 'react'
import LogoText from '../atoms/LogoText'
import NavItems from '../molecules/NavItems'
import MobileMenuToggle from '../atoms/MobileMenuToggle'
import MobileMenu from '../organisms/MobileMenu'
import { useTheme } from '../hooks/useTheme'
import classNames from 'classnames'
import NextLink from 'next/link'
import { NavigationMenuList, NavigationMenu } from 'ui/NavigationMenu'
import { twMerge } from 'tailwind-merge'

const HeaderBar = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [notAtTop, setNotAtTop] = useState(false)
  const [navValue, setNavValue] = useState('') // the currently active NavDropdown
  const [mobileMenu, setMobileMenu] = useState<'default' | 'close' | 'back'>(
    'back',
  )
  const { theme } = useTheme()

  const handleToggle = () => {
    if (mobileMenu !== 'back') {
      setShowMenu((open) => !open)
    }
    setNavValue('') // close the navigation viewport
  }

  useEffect(() => {
    const eventOpts: AddEventListenerOptions & EventListenerOptions = {
      passive: true,
    }
    const listener = () => {
      setNotAtTop(window.scrollY != 0)
    }

    window.addEventListener('scroll', listener, eventOpts)

    return () => {
      window.removeEventListener('scroll', listener, eventOpts)
    }
  }, [])

  useEffect(() => {
    // Set the correct mobile menu icon
    if (navValue === '') {
      setMobileMenu(showMenu ? 'close' : 'default')
    } else {
      setMobileMenu('back')
    }
  }, [showMenu, navValue])

  const themeclasses = classNames(
    'light:bg-gray-700 bright:bg-white black:bg-black transp-black:bg-black transp-light:bg-gray-700 transp-blue:bg-blue ' + // bg-color
      'transp-black:text-white transp-blue:text-white black:text-white transp-light:text-black text-black ', // text-color
    {
      'black:shadow-md light:shadow-md bright:shadow-md': notAtTop,
      // show a background for transparent themes as well for the mobile menu. (lg:but-not-on-desktop)
      'lg:transp-black:bg-opacity-0 lg:transp-light:bg-opacity-0 lg:transp-blue:bg-opacity-0':
        showMenu || (!notAtTop && navValue === ''),
      'transp-black:bg-black transp-light:bg-white transp-blue:bg-blue':
        showMenu,
    },
  )

  const mobileMenuClasses = classNames(
    // top-24 is to account for the height of the header (see h-24 lg:h-36 on header element)
    'animate-fade-in-up block lg:hidden fixed top-24 left-0',
    'w-full pt-8 h-full max-h-[calc(100vh-6rem)] overflow-y-auto', // the -6rem is to acommodate for the height of the header on mobile
    'transition duration-300',
    'text-black bg-white transp-black:bg-black black:bg-black transp-black:text-white black:text-white light:bg-gray-700',
    {
      'lg:top-36': notAtTop,
    },
  )

  const overlayClasses = twMerge(
    'lg:fixed transition-colors duration-200',
    navValue === ''
      ? '-z-10 h-0 w-0 opacity-0'
      : 'lg:z-20 lg:inset-0 bg-black/75 black:bg-gray-900/75',
  )

  return (
    <>
      <NavigationMenu
        className={themeclasses}
        onValueChange={(value) => setNavValue(value)}
        value={navValue}
      >
        <NavigationMenuList
          className={`max-w-outer-container xl:px-container-padding w-full mx-auto px-8 h-24 ${
            notAtTop ? '' : 'lg:h-36'
          } transition-all ease-linear duration-200 flex flex-row xl:grid xl:grid-cols-sm-left`}
        >
          <div className="h-full flex flex-1 lg:flex-none justify-between items-center">
            <NextLink href="/" aria-label="Home">
              <LogoText
                theme={theme}
                className=" mr-8 lg:mr-0"
                hideTextOnMobile={notAtTop}
              />
            </NextLink>
          </div>
          <div className="flex items-center flex-none ml-6 text-sm h-full lg:flex-1 xl:flex-none xl:ml-0">
            <NavItems show="lg" />
            <MobileMenuToggle
              type={mobileMenu}
              onClick={handleToggle}
              className="inline-block lg:hidden"
            />
          </div>
          {/* modal for the mobile menu. make sure it is always hidden when the toggle is not shown */}
          {showMenu && (
            <div className={mobileMenuClasses}>
              <MobileMenu />
            </div>
          )}
        </NavigationMenuList>
      </NavigationMenu>
      {/* add an overlay when the viewport opens */}
      <div className={overlayClasses}></div>
      {/* push content below header downwards. note that this height is the same as the header height */}
      <div className="h-24 lg:h-36 light:bg-gray-700 bright:bg-white black:bg-black" />
    </>
  )
}

export default HeaderBar
