import { ReactSVG } from 'react-svg'

export interface SvgImageProps {
  src: string
  className?: string
}

const SvgImage = ({ src, className }: SvgImageProps) => {
  return <ReactSVG src={src} className={className} />
}

export default SvgImage
