import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuTrigger,
} from 'ui/NavigationMenu'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

type Props = {
  text: string
  to: string
  children: ReactNode
}

const NavDropdown = ({ text, to, children }: Props) => {
  const { asPath } = useRouter()
  const isActive = new RegExp(`^${to}`).test(asPath)
  const underline = (
    <span
      className={twMerge(
        'block mt-1 max-w-0 group-hover:max-w-full duration-200 h-0.5 bg-blue',
        isActive && 'max-w-full',
      )}
    />
  )
  const itemCls = 'group transition duration-50 h-full'

  return (
    <NavigationMenuItem className="py-6">
      <NavigationMenuTrigger onPointerEnter={(e) => {e.preventDefault()}} onPointerMove={(e) => {e.preventDefault()}}>
        <div className={itemCls}>
          {text}
          {underline}
        </div>
        <FontAwesomeIcon
          icon={faAngleDown}
          className="ml-2 mb-1 inline-block align-middle h-4 cursor-pointer group-hover:text-blue -rotate-90 md:rotate-0 transition duration-200 group-data-[state=open]:rotate-180 group-data-[state=open]:text-blue"
        />
      </NavigationMenuTrigger>
      <NavigationMenuContent>
        <div className="max-w-outer-container xl:px-container-padding w-full mx-auto p-8 pt-0 lg:pt-8 lg:pb-16">
          {children}
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  )
}

export default NavDropdown
